import {Controller} from "@hotwired/stimulus"
export default class extends Controller{
    static targets = ["main"]

    connect(){
        const self = this;
        // @ts-ignore
        grecaptcha.ready(function() {
            // @ts-ignore
            grecaptcha.render(self.mainTarget);
        });

    }

}