import {Controller} from "@hotwired/stimulus"
export default class extends Controller{
    static targets = ["indicator", "tabHeader", "tab", "tabsContainer", "height"]
    active: Number = 0;

    connect(){
        const self = this;

        // @ts-ignore
        this.updateTabs(self, this.indicatorTarget.dataset.active)

        // @ts-ignore
        this.heightTargets.forEach(t => t.addEventListener('DOMSubtreeModified', function(e){
            // @ts-ignore
            self.refresh_height()
        }))
    }

    change(event){
        const self = this;
        const event_target = event.currentTarget;
        // @ts-ignore
        self.tabHeaderTargets.forEach((header, index) => {
            if(header === event_target){
                if(self.active !== index){
                    self.updateTabs(self, index);
                }
            }
        });
    }

    updateTabs(self, index){
        self.active = index;
        if(index === 0){
            self.indicatorTarget.removeAttribute('style');
            self.tabsContainerTarget.removeAttribute('style');
        } else {
            self.indicatorTarget.setAttribute('style', `--tw-translate-x: ${index}00%`);
            self.tabsContainerTarget.setAttribute('style', `--tw-translate-x: -${index}00%`);
        }
        const height = self.tabTargets[index].clientHeight;
        self.tabsContainerTarget.style.height = `${height}px`;
    }

    refresh_height(){
        // @ts-ignore
        const height = this.tabTargets[this.active].clientHeight;
        // @ts-ignore
        this.tabsContainerTarget.style.height = `${height}px`;
    }

}
