import {Controller} from "@hotwired/stimulus"

export default class extends Controller{
    static targets = ["flash"]

    connect() {
        // @ts-ignore
        if(this.hasFlashTarget){
            this.flash();
        }
    }

    flash(){
        const self = this;
        // @ts-ignore
        this.flashTargets.forEach(function(elem){
            let duration = elem.dataset.duration;
            if(duration === undefined){
                duration = 10000;
            } else {
                duration = parseInt(duration)
            }
            setTimeout(function(){
                elem.classList.replace('translate-y-2', 'translate-y-0');
                elem.classList.replace('opacity-0', 'opacity-100');
                elem.classList.replace('translate-x-2', 'translate-x-0');
            }, 500);
            setTimeout(function(){
                elem.classList.remove('transform', 'ease-out', 'duration-300', 'transition', 'translate-y-0', 'opacity-100', 'translate-x-0', 'translate-y-0');
            }, 1000);
            setTimeout( function(){
                self.remove_flash(elem)
            }, duration);
        })
    }

    close_flash(event){
        this.remove_flash(event.currentTarget);
    }

    remove_flash(elem){
        elem.classList.remove('translate-y-0', 'opacity-100', 'translate-x-0');
        elem.classList.add('transition', 'ease-in', 'duration-100');
        elem.classList.add('opacity-0');
        setTimeout(function(){
            elem.remove();
        }, 500);

    }
}
