import {Controller} from "@hotwired/stimulus"

export default class extends Controller{
    static targets = ['totalHoursInput', 'totalHours']
    temp_value: {};
    hours: number;

    initialize(){
        this.temp_value = {};
        this.hours = 0;
    }

    connect() {
        // @ts-ignore
        if(this.hasTotalHoursInputTarget && this.hasTotalHoursTarget){
            this.new_form_total_hours();
        }
    }

    new_form_total_hours(){
        const self = this;
        // @ts-ignore
        const body = this.totalHoursInputTarget;
        if(body !== undefined){
            const tds = Array.from(body.rows[0].children);
            tds.forEach(function(td: HTMLElement){
                const input = td.getElementsByTagName('input')[0];
                self.update_hours_and_temp(input);
                input.onchange = function(){
                    self.update_hours_and_temp(input);
                }
            })
        }
    }

    update_hours_and_temp(div){
        const id = div.attributes.id.value;
        const value = Number(div.value);
        let old_value = this.temp_value[id];
        if(old_value === undefined){
            old_value = 0;
        }
        const diff = value - old_value;
        this.temp_value[id] = value
        this.hours += diff;
        this.hours = Math.round(this.hours * 10) / 10
        // @ts-ignore
        this.totalHoursTarget.innerText = this.hours;
    }



}
