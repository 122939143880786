import {Controller} from "@hotwired/stimulus"
import {Turbo} from "@hotwired/turbo-rails";

export default class extends Controller{
    static targets = ['form', 'submit', 'toRemove', 'refresh']

    connect(){
        const self = this;

        // @ts-ignore
        this.formTarget.querySelectorAll('input').forEach(function(input){
            input.addEventListener('change', (event) => {
                self.enable_button();
            })
        })
    }

    refreshTargetConnected(target: Element){
        const self = this;
        // @ts-ignore
        this.formTarget.querySelectorAll('input').forEach(function(input){
            input.removeEventListener('change', (event) => {
                self.enable_button();
            })
        })
        // @ts-ignore
        this.formTarget.querySelectorAll('input').forEach(function(input){
            input.addEventListener('change', (event) => {
                self.enable_button();
            })
        })

        self.enable_button();
    }

    enable_button(){
        // @ts-ignore
        this.submitTargets.forEach(function(button){
            button.classList.remove('cursor-not-allowed', 'bg-gray-300', 'text-gray-500');
            button.classList.add('bg-green-500', 'hover:bg-green-700');
            button.disabled = false
        })

        // @ts-ignore
        this.toRemoveTargets.forEach(function(elem){
            elem.remove();
        })
    }

}
