import {Controller} from "@hotwired/stimulus"

export default class extends Controller{
    static targets = ['autocomplete']

    connect() {
        // @ts-ignore
        if(this.hasAutocompleteTarget){
            this.autocomplete();
        }
    };

    autocomplete(){
        // @ts-ignore
        const elems = this.autocompleteTargets;
        const self = this;
        elems.forEach(function(elem){
            const ac = M.Autocomplete.init(elem, {});
            const source = elem.dataset.source;
            self.get_options(source).then(data => {
                ac.updateData(data);
            });
        })
    }

    async get_options(source){
        const response = await fetch(source, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-forms-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        });
        return response.json();
    }


}
