import {Controller} from "@hotwired/stimulus"
import {Turbo} from "@hotwired/turbo-rails";

export default class extends Controller{
    static targets = ['input', 'value', 'selectList']

    connect(){
        const self = this;
        // @ts-ignore
        self.inputTarget.addEventListener('focus', function(e){
            // @ts-ignore
            if(self.selectListTargets.length > 0){
                // @ts-ignore
                self.selectListTarget.classList.remove('hidden');
            }
        })
        // @ts-ignore
        self.inputTarget.addEventListener('blur', function(e){
            // @ts-ignore
            if(self.selectListTargets.length > 0 && self.selectListTarget != e.relatedTarget){
                // @ts-ignore
                self.selectListTarget.classList.add('hidden');

                if(this.value != ''){
                    // @ts-ignore
                    const auto_selected = self.selectListTarget.querySelector('li:not(.hidden)')
                    const elem = auto_selected.getElementsByTagName('span')[0]
                    self.process_select(elem.innerText, elem.dataset.id)
                } else{
                    // @ts-ignore
                    self.valueTarget.value = null;
                }

            }
        })

        // @ts-ignore
        this.inputTarget.addEventListener('input', function(e){
            self.refresh_list()
        });
    }

    refresh_list(hidden = false){
        const self = this;
        // @ts-ignore
        const input = self.inputTarget;
        let url = input.dataset.url;
        const token = input.dataset.token;
        const value = input.value;
        if(value === ''){
            if(input.dataset.asJs == 'true') {
                // @ts-ignore
                Array.from(self.selectListTarget.children).forEach(child => {
                    // @ts-ignore
                    child.classList.remove('hidden');
                })
            } else {
                // @ts-ignore
                if(self.selectListTargets.length > 0){
                    // @ts-ignore
                    self.selectListTarget.classList.add('hidden');
                }
            }
        } else {
            if(input.dataset.asJs == 'true'){
                // @ts-ignore
                Array.from(self.selectListTarget.children).forEach(child => {
                    // @ts-ignore
                    if(value != '' && value != null && !child.getElementsByTagName('span')[0].textContent.trim().split(' - ')[0].toLowerCase().startsWith(value.toLowerCase())){
                        // @ts-ignore
                        child.classList.add('hidden');
                    } else {
                        // @ts-ignore
                        child.classList.remove('hidden');
                    }
                })
            } else{
                // const current_page = window.location.href
                url = url + `?query=${value}&token=${token}&hidden=${hidden}`
                fetch(url,{
                    headers: {
                        Accept: 'text/vnd.turbo-stream.html'
                    }
                }).then(r => r.text())
                    .then(html => Turbo.renderStreamMessage(html));
                // Turbo.visit(url, {action: 'replace'});
                // window.history.replaceState({additionalInformation: 'Replacing temp url'}, 'VATATIS', current_page);
            }
        }
    }

    process_select(name, id){
        // @ts-ignore
        this.inputTarget.value = name.trim();
        // @ts-ignore
        this.valueTarget.value = id;
        this.refresh_list(true)

        const evt = new Event('autocomplete_select', {
            bubbles: true,
            cancelable: true,
            composed: false
        });

        // @ts-ignore
        this.valueTarget.dispatchEvent(evt);

        // @ts-ignore
        this.selectListTarget.classList.add('hidden');
    }

    select(event: PointerEvent){
        // @ts-ignore
        const target : HTMLElement = event.target;

        let name: string;
        let id: string;

        if(target.tagName == 'SPAN'){
            name = target.innerText;
            id = target.dataset.id;
        } else {
            const elem = target.getElementsByTagName('span')[0]
            name = elem.innerText;
            id = elem.dataset.id;
        }

        this.process_select(name, id);
    }

    select_toggle(){
        // @ts-ignore
        this.selectListTarget.classList.toggle('hidden');
    }

}
