import {Controller} from "@hotwired/stimulus"
import {Turbo} from "@hotwired/turbo-rails";

export default class extends Controller{

    visit(event: PointerEvent){
        let target: HTMLElement = event.target as HTMLElement;
        if(target.nodeName == 'TD'){
            target = target.parentElement;
        }
        let url = target.dataset.link;

        Turbo.visit(url);
    }

}