import {Controller} from "@hotwired/stimulus"

export default class extends Controller{
    static targets = ["notifications", "notificationTemplate"]

    add_notification(){
        // @ts-ignore
        const current_notification_count = parseInt(this.notificationsTarget.dataset.notifications);
        // @ts-ignore
        const prefix = this.notificationsTarget.dataset.notificationsprefix;

        // @ts-ignore
        const html = this.notificationTemplateTarget.content.cloneNode(true);
        html.querySelectorAll('[id="notification_$$"]')[0].setAttribute('id', `${prefix}_notification_${current_notification_count}`)
        html.querySelectorAll('button[data-notification="$$"]')[0].setAttribute('data-notification', `${current_notification_count}`)
        const notification_count_div = html.querySelectorAll('[id="notification_count___"]')[0]
        notification_count_div.setAttribute('id', `notification_count_${current_notification_count}`)
        notification_count_div.setAttribute('name', `notification_count[${current_notification_count}]`)
        const notification_label_div = html.querySelectorAll('[for="notification_count___"]')[0]
        notification_label_div.setAttribute('for', `notification_count_${current_notification_count}`)

        const notification_unit_div = html.querySelectorAll('[id="notification_unit___"]')[0]
        notification_unit_div.setAttribute('id', `notification_unit_${current_notification_count}`)
        notification_unit_div.setAttribute('name', `notification_unit[${current_notification_count}]`)

        // @ts-ignore
        this.notificationsTarget.append(html);
        // @ts-ignore
        this.notificationsTarget.dataset.notifications = current_notification_count + 1;
    }

    remove_notification(event){
        // @ts-ignore
        const current_notification_count = parseInt(this.notificationsTarget.dataset.notifications);
        // @ts-ignore
        const prefix = this.notificationsTarget.dataset.notificationsprefix;
        let elem = event.target;
        while(elem.tagName != 'BUTTON'){
            elem = elem.parentElement;
        }
        const notification = elem.dataset.notification;
        const notification_elem = document.getElementById(`${prefix}_notification_${notification}`);
        notification_elem.querySelectorAll('input')[0].dispatchEvent(new Event('change'))
        notification_elem.remove();
        if(notification == 0){
            // @ts-ignore
            this.notificationsTarget.innerHTML = null;
        }
        // @ts-ignore
        this.notificationsTarget.dataset.notifications = current_notification_count - 1;
    }
}
