import {Controller} from "@hotwired/stimulus"
import {callable_functions} from "./support/callable";

export default class extends Controller{
    static targets = ["tabs", "sidenav", "tooltip", "collapsible", "dropdown", "select", "datepicker",
        "timepicker", "slider", "modal", "autocomplete"]

    connect() {
        // @ts-ignore
        if(this.hasTabsTarget){
            this.tabs()
        }
        // @ts-ignore
        if(this.hasSidenavTarget){
            this.sidenav();
        }
        // @ts-ignore
        if(this.hasTooltipTarget){
            this.tooltip();
        }
        // @ts-ignore
        if(this.hasCollapsibleTarget){
            this.collapsible();
        }
        // @ts-ignore
        if(this.hasDropdownTarget){
            this.dropdown();
        }
        // @ts-ignore
        if(this.hasSelectTarget){
            this.select();
        }
        // @ts-ignore
        if(this.hasDatepickerTarget){
            this.datepicker();
        }
        // @ts-ignore
        if(this.hasTimepickerTarget){
            this.timepicker();
        }
        // @ts-ignore
        if(this.hasSliderTarget){
            this.slider();
        }
        // @ts-ignore
        if(this.hasModalTarget){
            this.modal();
        }

        M.updateTextFields();
    }


    modal(){
        // @ts-ignore
        const elems = this.modalTargets;

        // @ts-ignore
        M.Modal.init(elems, {});
    }
    slider(){
        // @ts-ignore
        const elems: MElements = this.sliderTargets;

        // @ts-ignore
        const sliders = M.Slider.init(elems, {
            indicators: true
        })

        sliders.forEach(function(slider){
            slider.start();
        })
    }

    timepicker(){
        // @ts-ignore
        this.timepickerTargets.forEach(function(elem){
            const val = elem.value;
            if(val !== undefined){
                // @ts-ignore
                M.Timepicker.init(elem, {
                    defaultTime: val
                })
            } else{
                // @ts-ignore
                M.Timepicker.init(elem, {})
            }
        })
    }

    datepicker(){
        // @ts-ignore
        this.datepickerTargets.forEach(function(elem){
            const start_year = elem.getAttribute('start_year');
            const end_year = elem.getAttribute('end_year');
            let start_date = undefined;
            let end_date = undefined;
            if(start_year !== undefined){
                start_date = new Date(parseInt(start_year), 0, 1);
            }
            if(end_year !== undefined){
                end_date = new Date(parseInt(end_year), 12, 30)
            }

            if(start_date !== undefined && end_date !== undefined){
                // @ts-ignore
                M.Datepicker.init(elem, {
                    minDate: start_date,
                    maxDate: end_date,
                    yearRange: [parseInt(start_year), parseInt(end_year)],
                    showDaysInNextAndPreviousMonths: true
                })
            } else if(start_date !== undefined){
                // @ts-ignore
                M.Datepicker.init(elem, {
                    minDate: start_date,
                    yearRange: (new Date().getFullYear() - parseInt(start_year)),
                    showDaysInNextAndPreviousMonths: true
                })
            } else if(end_date !== undefined){
                // @ts-ignore
                M.Datepicker.init(elem, {
                    maxDate: end_date,
                    yearRange: (parseInt(end_year) - new Date().getFullYear()),
                    showDaysInNextAndPreviousMonths: true
                })
            } else {
                // @ts-ignore
                M.Datepicker.init(elem, {
                    yearRange: 100,
                    showDaysInNextAndPreviousMonths: true
                })
            }

        });
    }

    select(){
        // @ts-ignore
        this.selectTargets.forEach(function(elem){
            const onChange = elem.dataset.onChange;
            // @ts-ignore
            M.FormSelect.init(elem, {});
            if (onChange !== undefined){
                elem.onchange = function(){
                    callable_functions[onChange](this);
                }
                callable_functions[onChange](this);
            }
        });
    }

    dropdown(){
        // @ts-ignore
        this.dropdownTargets.forEach(function(elem){
            // @ts-ignore
            M.Dropdown.init(elem, {
                'coverTrigger': false,
                'constrainWidth': false
            });
        })
    }

    tabs(){
        // @ts-ignore
        this.tabsTargets.forEach(function(elem){
            // @ts-ignore
            M.Tabs.init(elem, {});
        })
    }

    sidenav(){
        // @ts-ignore
        this.sidenavTargets.forEach(function(elem){
            // @ts-ignore
            M.Sidenav.init(elem, {});
        })
    }

    tooltip(){
        // @ts-ignore
        this.tooltipTargets.forEach(function(elem){
            // @ts-ignore
            M.Tooltip.init(elem, {});
        })
    }

    collapsible(){
        // @ts-ignore
        this.collapsibleTargets.forEach(function(elem){
            // @ts-ignore
            M.Collapsible.init(elem, {});
        })
    }
}
